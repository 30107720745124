import { global } from ":global";
import { _Firebase } from "./_model";

export async function onPollUpdate(props: _Firebase.OnPollUpdateProps) {
  global.fb.rtdb.onValueChanged({
    path: "/poll/" + String(props.eventId),
    callback: (snap) => {
      props.onUpdate?.(snap.val());
    },
  });
}
